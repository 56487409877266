import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Header";
import Home from "./Home";
import EventDetails from "./EventDetails";
import CreatePost from "./CreatePost";
import SignIn from "./SignIn";
import ProtectedRoute from "./ProtectedRoute";
import { AuthContextProvider } from "../context/AuthContext";
import { EventContextProvider } from "../context/EventContext";
import Footer from "./Footer";

function App() {
  return (
    <AuthContextProvider>
      <EventContextProvider>
        <div className="flex min-h-[100vh] flex-col">
          <div>
            <Router>
              <div className="sticky top-0 z-50">
                <Header />
              </div>
              <Routes>
                <Route exact path="/" Component={Home} />
                <Route exact path="/events" Component={Home} />
                <Route path="/events/:eventId" Component={EventDetails} />
                <Route exact path="/signin" Component={SignIn} />
                <Route
                  exact
                  path="/createpost"
                  element={
                    <ProtectedRoute>
                      <CreatePost />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </Router>
          </div>
          <div className="mt-auto">
            <Footer />
          </div>
        </div>
      </EventContextProvider>
    </AuthContextProvider>
  );
}

export default App;
