import { EventResources } from "../context/EventContext";
import { ReactComponent as HeartIcon } from "../assets/svgs/red-heart.svg";

export default function Footer() {
  const { imgFullScreen } = EventResources();

  return (
    <footer className={`${imgFullScreen ? "hidden" : ""} fs-100`}>
      <p className="flex items-center justify-center">
        Made with{" "}
        <svg width="15" height="14" className="mx-1">
          <HeartIcon />
        </svg>{" "}
        in ABUAD
      </p>
    </footer>
  );
}
