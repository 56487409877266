import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { getAuth } from "firebase/auth";

// import dotenv from "dotenv";

// dotenv.config();

const firebaseConfig = {
	apiKey: "AIzaSyCGKixBvdxYe3yLg48GXDiRJEbW7dGh8Po",
	authDomain: "wknd-47e66.firebaseapp.com",
	projectId: "wknd-47e66",
	storageBucket: "wknd-47e66.appspot.com",
	messagingSenderId: "951130048577",
	appId: "1:951130048577:web:10f95c16106ad3fde2dec0",
	measurementId: "G-F7X6HRFWZY",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
// const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);

export {ref, uploadBytes, auth, storage, db as default};
