import "../styles/index.css";

import Category from "./Category";

import { ReactComponent as ShareIcon } from "../assets/svgs/share-nodes.svg";
import { ReactComponent as ReminderIcon } from "../assets/svgs/bell-filled.svg";
import { ReactComponent as LocationIcon } from "../assets/svgs/location-dot.svg";
import { ReactComponent as OnlineEventIcon } from "../assets/svgs/video.svg";
import { Suspense } from "react";
import { EventPosterSkeleton } from "./skeletons/EventPosterSkeleton";
import { Link } from "react-router-dom";

function Event(props) {
  const isFirstChild = props.isFirstChild;

  return (
    <div
      id={props.id}
      className={`event flex flex-col  text-primary-400 ${
        isFirstChild ? "first-child" : null
      }`}
    >
      <div className="px-[1.3rem]">
        <div className="wrapper">
          <div className="event__info">
            <Category
              iconClassNames={["h-[12px]", "w-[12px]"]}
              textClassNames={["fs-100"]}
              category={props.category || <span>Announcement</span>}
            />
            <div className="ff-accent fs-200 uppercase">
              <span className="fw-800">{props.date.substring(0, 3)}</span>
              <span className="fw-500">{props.date.substring(4)} </span>{" "}
              {props.startTime && (
                <span className="fw-300">
                  | {props.startTime.replace(/\s/, "")}
                </span>
              )}
            </div>
            <Link to={`/events/${props.id}`} key={props.id}>
              <h2 className="event__title ff-title fs-700 fw-700 text-slate-900">
                {props.title}
              </h2>
            </Link>
            <div className="event__venue icon-flex-container">
              {props.isOnline ? (
                <>
                  <svg width="15" height="14" className="-rotate-4">
                    <OnlineEventIcon />
                  </svg>
                  <p className="ff-accent fs-300 fw-700 single-line">Online</p>
                </>
              ) : (
                <>
                  <svg width="9" height="14">
                    <LocationIcon />
                  </svg>
                  <p className="ff-accent fs-300 fw-700 single-line">
                    {props.venue ? props.venue : <i>Unknown</i>}
                  </p>
                </>
              )}
            </div>
            {props.organizers && props.organizers.length > 1 ? (
              <p className="event__organizer | single-line ff-title fs-100 fw-600">
                <span className="event__organizer-label ff-accent fw-500">
                  Organizers:{" "}
                </span>
                {props.organizers.join(", ")}{" "}
              </p>
            ) : (
              <p className="event__organizer | single-line ff-title fs-100 fw-600">
                <span className="event__organizer-label ff-accent fw-500">
                  Organizer:{" "}
                </span>
                {props.organizers[0]}
              </p>
            )}
          </div>
          <Link to={`/events/${props.id}`} key={props.id}>
            <Suspense fallback={<EventPosterSkeleton />}>
              <div className="event__img">
                <img loading="lazy" src={props.posterURL} alt=""></img>
              </div>
            </Suspense>
          </Link>
        </div>
        <hr className="hr"></hr>
        <div className="action-div items mt-auto flex flex-row items-stretch justify-between gap-2 py-3">
          <div>
            <button className="flex h-full items-center gap-1 rounded-full bg-[#F5F5F5] px-3 py-1 text-[12px] font-semibold text-[#6A6A6A]">
              <ShareIcon className="h-4 w-4 fill-[#6A6A6A]" />{" "}
              <span>Share</span>
            </button>
          </div>

          <div>
            <button className="flex h-full items-center rounded-full bg-[#F5F5F5] px-3 py-1 text-[12px] font-semibold text-[#6A6A6A]">
              <a
                href={`${props.addToCalendarLink}`}
                target="_blank"
                rel="noreferrer"
                className="flex items-center gap-1"
              >
                <ReminderIcon className="h-4 w-4 rounded-[5px] fill-[#6A6A6A] " />{" "}
                <span className="text-left">Set reminder</span>
              </a>
            </button>
          </div>
          {props.description &&
          <div className="flex items-end justify-end py-1 text-[#6A6A6A]">
            <Link
              to={`/events/${props.id}`}
              key={props.id}
              className="text-[12px] underline"
            >
              See description
            </Link>
          </div> }
        </div>
      </div>
      <div className=" h-2 w-full bg-gray-300"></div>
    </div>
  );
}

export default Event;
