import { createContext, useContext, useState } from "react";

import {
	signInWithEmailAndPassword,
	signOut,
	createUserWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "../firebase-config.mjs";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
	const [user, setUser] = useState({});
	const [token, setToken] = useState();

	const checkUser = async () => {
		auth.onAuthStateChanged((userCred) => {
			if (userCred) {
				// User is signed in
				console.log("User is signed in: " + userCred.email);
				userCred.getIdToken().then((userToken) => {
					setToken(userToken);
				});
			} else {
				console.log("User is signed out");
			}
			setUser(userCred);
		});
	};

	auth.onAuthStateChanged((userCred) => {
		if (userCred) {
			// User is signed in
			// console.log("User is signed in: " + userCred.email);
			userCred.getIdToken().then((userToken) => {
				setToken(userToken);
			});
		} else {
			// console.log("User is signed out");
		}
		setUser(userCred);
	});

	const createUser = (email, password) => {
		return createUserWithEmailAndPassword(auth, email, password);
	};

	const logOut = async () => {
		try {
			await signOut(auth);
		} catch (err) {
			console.error(err);
		}
	};

	const signIn = async (email, password) => {
		try {
			await signInWithEmailAndPassword(auth, email, password);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<UserContext.Provider
			value={{ createUser, signIn, logOut, checkUser, user, token }}
		>
			{children}
		</UserContext.Provider>
	);
};

export const UserAuth = () => {
	return useContext(UserContext);
};
