// Stylesheets
import "../styles/index.css";
import "../styles/utilities.css";

import React, { Suspense, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";

// Components
import Event from "./Event.jsx";
import Announcement from "./Announcement.jsx";
import Filters from "./Filters.jsx";

// Icons
import { ReactComponent as XIcon } from "../assets/svgs/xmark.svg";

// Functions
import { formatDate, formatTime } from "../utils/formatDateandTime.js";

import { UserAuth } from "../context/AuthContext";
import { EventResources } from "../context/EventContext.js";
import EventSkeleton from "./skeletons/Skeletons.jsx";

/*
	1. Set up main get request✅
	2. Implement api call on scroll to bottom 
		- First call gets first week only
		- Second call gets second week data 
		- Third call gets all remaining data
	3. Set up cloud function to move outdated events to another database
	4. Implement click to load a single event
	5. Implement filters ✅
	6. Enable offline support
	7. Set date to "today" or "tomorrow" where applicable
	8. Create "This Week", "Next Week" and "Upper Weeks" headings
*/

function Home() {
  const {
    resources,
    fetchEntries,
    entriesLoading,
    activatedFilters,
    sendFilters,
  } = EventResources();

  let isFiltered = activatedFilters?.length > 0;

  const [searchParams, setSearchParams] = useSearchParams();

  const [thisWeekEvents, setThisWeekEvents] = useState();
  const [nextWeekEvents, setNextWeekEvents] = useState();
  const [thisWeekendEvents, setThisWeekendEvents] = useState();
  const [thisMonthEvents, setThisMonthEvents] = useState();
  const [nextMonthEvents, setNextMonthEvents] = useState();
  const [laterOnEvents, setLaterOnEvents] = useState();

  const todaysDate = DateTime.local();

  const thisWeek = [],
    thisWeekend = [],
    nextWeek = [],
    thisMonth = [],
    nextMonth = [],
    laterOn = [];

  /*
		This week 
		This weekend
		Next week
		This month 
		Next month 
		Later on
	*/

  const modifyDateAndTime = (element) => {
    if (element.date) {
      element.date = formatDate(element.date);
    }
    if (element.startTime) {
      element.startTime = formatTime(element.startTime);
    }
    if (element.stopTime) {
      element.stopTime = formatTime(element.stopTime);
    }
  };

  // <p>
  // 	Image by{" "}
  // 	<a href='https://www.freepik.com/free-photo/group-happy-teenagers-taking-selfie_7605815.htm#query=black%20teens&position=8&from_view=keyword&track=ais&uuid=97ab9bb5-efd5-4daa-b743-e90d31d66895'>
  // 		Freepik
  // 	</a>
  // </p>;
  // <p>
  // 	Image by{" "}
  // 	<a href='https://www.freepik.com/free-photo/medium-shot-happy-people-dancing-together_28269355.htm#&position=15&from_view=collections&uuid=5f889c95-bf84-4b6b-a7c7-6dd3d4c00079'>
  // 		Freepik
  // 	</a>
  // </p>;

  useEffect(() => {
    console.log(activatedFilters);
  }, [activatedFilters]);

  useEffect(() => {
    if (!resources) {
      fetchEntries();
    } else {
      // Create a deep copy to avoid mutating resources array
      let resourcesDeepCopy = JSON.parse(JSON.stringify(resources));

      for (const element of resourcesDeepCopy) {
        let eventDate = DateTime.fromISO(element.date);

        modifyDateAndTime(element);

        if (todaysDate.hasSame(eventDate, "week")) {
          if (eventDate.isWeekend) {
            thisWeekend.push(element);
          } else {
            thisWeek.push(element);
          }
          continue;
        }

        if (eventDate.weekNumber === todaysDate.weekNumber + 1) {
          nextWeek.push(element);
          continue;
        }

        if (todaysDate.hasSame(eventDate, "month")) {
          thisMonth.push(element);
          continue;
        }

        if (eventDate.month === todaysDate.month + 1) {
          nextMonth.push(element);
          continue;
        }

        laterOn.push(element);
      }
    }

    setThisWeekEvents(thisWeek);
    setThisWeekendEvents(thisWeekend);
    setNextWeekEvents(nextWeek);
    setThisMonthEvents(thisMonth);
    setNextMonthEvents(nextMonth);
    setLaterOnEvents(laterOn);
  }, [resources]);

  let showEvent = (event) => {
    return event.category === "announcement" ? (
      <Announcement
        key={event.id}
        id={event.id}
        category={event.category}
        title={event.title}
        venue={event.venue}
        organizers={event.organizers}
        date={event.date}
        startTime={event.startTime}
        stopTime={event.stopTime}
        description={event.description}
        posterURL={event.posterURL}
        addToCalendarLink={event.addToCalendarLink}
      />
    ) : (
      <Event
        id={event.id}
        category={event.category}
        title={event.title}
        venue={event.venue}
        organizers={event.organizers}
        date={event.date}
        startTime={event.startTime}
        stopTime={event.stopTime}
        description={event.description}
        posterURL={event.posterURL}
        isOnline={event.isOnline}
        addToCalendarLink={event.addToCalendarLink}
      />
    );
  };

  const [currentSlide, setCurrentSlide] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide === 1 ? 2 : 1));
    }, 7000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex min-h-[100vh] flex-col">
      {/* BANNER */}
      <div className="relative min-h-[50vh]">
        <img
          loading="eager"
          className="absolute inset-0 h-full w-full object-cover"
          alt=""
          src="/wkndbanner.jpg"
        />
        <div className="absolute inset-0 bg-black/50"></div>

        <p className="absolute w-full bg-slate-950 px-[1.3rem] py-1 text-center text-xs text-slate-50">
          We are currently doing maintenance on the site.{" "}
          <a href="https://wa.link/qy5dts" target="_blank" rel="noreferrer">
            <br></br> Please{" "}
            <span className="fancy-link">contact the creator</span> for any
            inquiries.
          </a>
        </p>
        <div
          className={`absolute left-0 top-0 h-full w-full transform transition-transform duration-700 ease-in-out ${
            currentSlide === 1 ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          {/* className={`absolute left-0 top-0 h-full w-full transform transition-transform duration-700 ease-in-out`}
        > */}
          <div className="banner-content absolute">
            <h1 className="banner-text flex flex-col px-5 font-body text-xl">
              <span className="banner-fancy-text">WELCOME BACK!</span>{" "}
              {/* <span>week is here!</span> */}
              🚗🚕
            </h1>
            <div className="scroll-down1">
              <a href="#events">
                <svg width="35" height="35" viewBox="0 0 24 24">
                  <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div
          className={`absolute left-0 top-0 h-full w-full transform transition-transform duration-700 ease-in-out ${
            currentSlide === 2 ? "translate-x-0" : "translate-x-full"
          }`}
          // className={`absolute left-0 top-0 h-full w-full transform transition-transform duration-700 ease-in-out`}
        >
          <div className="banner-content absolute">
            <h1 className="banner-text flex flex-col px-5 font-body text-xl">
              <span className="banner-fancy-text">DISCOVER</span>{" "}
              <span>exciting events on campus</span>
            </h1>
            <div className="scroll-down1">
              <a href="#events">
                <svg width="35" height="35" viewBox="0 0 24 24">
                  <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* SCROLL-TO DIV */}
      <div id="events"></div>

      <div className="mt-5">
        {isFiltered && (
          <div
            className={`flex justify-end px-[1.3rem]`}
            onClick={() => {
              fetchEntries();
              sendFilters([]);
              setSearchParams();
            }}
          >
            <div className="flex flex-row items-center gap-1 rounded-full border-2 border-dotted border-accent-bright px-5 py-[0.2rem] align-middle">
              <p className="font-lato text-xs text-accent-bright">
                Clear filters
              </p>{" "}
              <svg className="h-[.9rem] w-[.9rem]">
                <XIcon />
              </svg>
            </div>
          </div>
        )}
        {entriesLoading ? (
          // LOADING SKELETONS
          <div className="mt-7 px-[1.3rem]">
            <EventSkeleton />
            <EventSkeleton />
            <EventSkeleton />
            <EventSkeleton />
          </div>
        ) : (
          <div>
            {/* ALL EVENTS */}
            {resources && resources.length < 1 && (
              <div className="align-center ff-accent mx-[1.3rem] mt-6 flex flex-row justify-center border border-gray-400 bg-neutral-100 p-4">
                <p className="text-md ">
                  There are no events or announcements to show
                </p>
              </div>
            )}
            {thisWeekEvents && thisWeekEvents.length > 0 && (
              <>
                <h1 className="week-header ff-accent fs-500 fw-400 px-[1.3rem]">
                  This week
                </h1>
                {thisWeekEvents.map((event) => {
                  return showEvent(event);
                })}
              </>
            )}

            {thisWeekendEvents && thisWeekendEvents.length > 0 && (
              <>
                <h1 className="week-header ff-accent fs-500 fw-400 px-[1.3rem]">
                  This weekend
                </h1>
                {thisWeekendEvents.map((event) => {
                  return showEvent(event);
                })}
              </>
            )}

            {nextWeekEvents && nextWeekEvents.length > 0 && (
              <>
                <h1 className="week-header ff-accent fs-500 fw-400 px-[1.3rem]">
                  Next week
                </h1>
                {nextWeekEvents.map((event) => {
                  return showEvent(event);
                })}
              </>
            )}

            {thisMonthEvents && thisMonthEvents.length > 0 && (
              <>
                <h1 className="week-header ff-accent fs-500 fw-400 px-[1.3rem]">
                  Later this month
                </h1>
                {thisMonthEvents.map((event) => {
                  return showEvent(event);
                })}
              </>
            )}

            {nextMonthEvents && nextMonthEvents.length > 0 && (
              <>
                <h1 className="week-header ff-accent fs-500 fw-400 px-[1.3rem]">
                  Next month
                </h1>
                {nextMonthEvents.map((event) => {
                  return showEvent(event);
                })}
              </>
            )}

            {laterOnEvents && laterOnEvents.length > 0 && (
              <>
                <h1 className="week-header ff-accent fs-500 fw-400 px-[1.3rem]">
                  Coming up much later
                </h1>
                {laterOnEvents.map((event) => {
                  return showEvent(event);
                })}
              </>
            )}
          </div>
        )}
      </div>
      <div className="mt-auto">
        <div className="align-center ff-accent mb-10 mt-[calc(2.5rem-1.2rem)] flex flex-col justify-center bg-neutral-100">
          <p className="text-md italic">That's all we know for now... </p>
          <p className="fs-300">
            Can't find your event?{" "}
            <a href="https://wa.link/qy5dts" target="_blank" rel="noreferrer">
              <span className="fancy-link">Contact the admin</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
