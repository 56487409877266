import { DateTime } from "luxon";

const formatDate = (date) => {
	const luxonDate = DateTime.fromISO(date);
	const formattedDate = luxonDate.toFormat("EEE, LLL dd");
	return formattedDate;
};

const formatTime = (time) => {
	const [hours, minutes] = time.split(":");
	const newTime = new Date(0, 0, 0, hours, minutes);

	const formattedTime = newTime.toLocaleTimeString("en-US", {
		hour: "numeric",
		minute: "numeric",
	});

	return formattedTime;
};

const formatDateFull = (date) => {
	const luxonDate = DateTime.fromISO(date);
	const formattedDate = luxonDate.toFormat("EEEE, LLLL d, yyyy");
	return formattedDate;
}

export { formatDate, formatTime, formatDateFull };
