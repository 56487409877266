import { Link, useLocation } from "react-router-dom";
import { EventResources } from "../context/EventContext";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

// COMPONENTS
import Filters from "./Filters.jsx";
import TestFilter from "./TestFilter";

// ICONS
import { ReactComponent as FilterIcon } from "../assets/svgs/filter.svg";
import { ReactComponent as CloseIcon } from "../assets/svgs/xmark.svg";

export default function Header() {
  const { imgFullScreen, isMenuOpen, toggleMenu } = EventResources();

  const location = useLocation();

  const isHomePage = location.pathname === "/";

  const minuVars = {
    initial: {
      scaleY: 0,
    },
    animate: {
      scaleY: 1,
      transition: {
        duration: 0.5,
        ease: [0, 0.55, 0.45, 1],
      },
    },
    exit: {
      scaleY: 0,
      transition: {
        delay: 0.5,
        duration: 0.5,
        ease: [0.37, 0, 0.63, 1],
      },
    },
  };

  const manuVars = {
    initial: { opacity: 0 }, // Initial position and opacity when menu is closed
    animate: { opacity: 1 }, // Animation when menu is opened
    exit: { opacity: 0 }, // Animation when menu is closed
    transition: { type: "spring", stiffness: 100, damping: 15 }, // Adjust the transition for desired effect
  };

  const menuVars = {
    initial: {
      opacity: 0, // Add opacity for smoother transition
    },
    animate: {
      opacity: 1, // Make menu fully visible when scaled
      transition: {
        duration: 0.5,
        ease: [0.55, 0.05, 0.55, 0.95], // Modified ease for smoother transition
      },
    },
    exit: {
      opacity: 0, // Hide menu while scaling down
      transition: {
        duration: 0.5,
        ease: [0.55, 0.05, 0.55, 0.95], // Use the same ease for consistency
      },
    },
  };

  return (
    <header>
      <nav
        className={`${
          imgFullScreen ? "hidden" : ""
        } logo flex items-center ${isHomePage ? "justify-between" : "justify-center"} px-[1.3rem]`}
      >
        <div>
          <Link to={"/"}>
            <h1 className="">WEEKEND</h1>
          </Link>
        </div>

        {isHomePage && (
          <div
            className="cursor-pointer rounded-md border border-slate-900 px-4 py-1 text-xs font-normal not-italic text-slate-900"
            onClick={() => toggleMenu()}
          >
            {isMenuOpen ? (
              <div className="flex flex-row items-center justify-center gap-1">
                <svg className="h-4 w-4">
                  <CloseIcon />
                </svg>
                <p>Close</p>
              </div>
            ) : (
              <div className="flex flex-row items-center justify-center gap-[0.35rem] align-middle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-list-filter"
                >
                  <path d="M3 6h18" />
                  <path d="M7 12h10" />
                  <path d="M10 18h4" />
                </svg>
                <p>Filter Events</p>
              </div>
            )}
          </div>
        )}
      </nav>
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            variants={menuVars}
            initial="initial"
            animate="animate"
            exit="exit"
            className="absolute flex h-[100dvh] w-full origin-top flex-col justify-center overflow-scroll bg-white bg-opacity-80 px-6 backdrop-blur-sm"
          >
            <Filters />
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
}
