import "../styles/index.css";
import { Link } from "react-router-dom";

import { UserAuth } from "../context/AuthContext";

// Icons
import { ReactComponent as LocationIcon } from "../assets/svgs/location-dot.svg";
import { ReactComponent as ShareIcon } from "../assets/svgs/share-nodes.svg";
import { ReactComponent as ReminderIcon } from "../assets/svgs/bell-filled.svg";

//Components
import Category from "./Category";

function Announcement(props) {
  const { token } = UserAuth();
  const adminMode = token ? true : false;

  const isFirstChild = props.isFirstChild;

  let collegesDisplay = (colleges) => {
    if (colleges[0] === "" || colleges[0] === "all colleges") {
      return <p className="college ff-body fs-100 fw-600">all colleges</p>;
    } else {
      if (colleges.length === 1) {
        return (
          <p className="college ff-body fs-100 fw-600">
            <span className="college-label ff-accent fw-500">
              Affected college:{" "}
            </span>
            {props.organizers.join(", ")}{" "}
          </p>
        );
      } else if (colleges.length > 1) {
        return (
          <p className="college ff-body fs-100 fw-600">
            <span className="college-label ff-accent fw-500">
              Affected colleges:{" "}
            </span>
            {props.organizers.join(", ")}{" "}
          </p>
        );
      }
    }
  };

  return (
    <>
      <div
        className={`event announcement  ${isFirstChild ? "first-child" : null}`}
      >
        <div className="px-[1.3rem]">
          <div className="wrapper">
            <div className="event__info announcement__info">
              <Category
                iconClassNames={["h-[12px]", "w-[12px]"]}
                textClassNames={["fs-100"]}
                category="announcement"
              />
              <div className="event__date ff-accent fs-200 uppercase">
                <span className="fw-800">{props.date.substring(0, 3)}</span>
                <span className="fw-500">{props.date.substring(4)} </span>{" "}
                {props.startTime ? (
                  <span className="fw-300">
                    | {props.startTime.replace(/\s/, "")}
                  </span>
                ) : null}
              </div>
              <h2 className="event__title ff-title fs-700 fw-700">
                {props.title}
              </h2>
              {props.venue ? (
                <div className="event__venue icon-flex-container">
                  <svg width="9" height="14">
                    <LocationIcon />
                  </svg>
                  <p className="ff-accent fs-300 fw-700 single-line">
                    {props.venue ? props.venue : <i>Unknown</i>}
                  </p>
                </div>
              ) : null}
              {props.organizers ? collegesDisplay(props.organizers) : null}
              {/* {(props.description || adminMode) && (
                <Link
                  to={`/events/${props.id}`}
                  state={props}
                  className="more-info"
                >
                  see details
                </Link>
              )} */}
            </div>
          </div>
          <hr className="hr"></hr>
          <div className="action-div items mt-auto flex flex-row items-stretch justify-between gap-2 py-3">
            <div>
              <button className="flex h-full items-center gap-1 rounded-full bg-[#F5F5F5] px-3 py-1 text-[12px] font-semibold text-[#6A6A6A]">
                <ShareIcon className="h-4 w-4 fill-[#6A6A6A]" />{" "}
                <span>Share</span>
              </button>
            </div>

            <div>
              <button className="flex h-full items-center rounded-full bg-[#F5F5F5] px-3 py-1 text-[12px] font-semibold text-[#6A6A6A]">
                <a
                  href={`${props.addToCalendarLink}`}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center gap-1"
                >
                  <ReminderIcon className="h-4 w-4 rounded-[5px] fill-[#6A6A6A] " />{" "}
                  <span className="text-left">Set reminder</span>
                </a>
              </button>
            </div>

            <div
              className={`flex items-end justify-end py-1 text-[#6A6A6A] ${props.description || adminMode ? `visible` : `hidden`}`}
            >
              <Link
                to={`/events/${props.id}`}
                state={props}
                className="text-[12px] underline"
              >
                See details
              </Link>
            </div>
          </div>
        </div>
        <div className="h-2 w-full bg-gray-300"></div>
      </div>
    </>
  );
}

export default Announcement;
