import React from "react";


export default function EventSkeleton() {
	return (
		<div className={'event flex flex-col mb-[1.2rem]'}>
			<div className='wrapper'>
				<div className='flex flex-col w-full mr-2'>
					<div className='shimmer bg-gray-300 h-3 rounded-md w-16'></div>
					<div className='my-3 shimmer-div'>
						<div className='title-line-1 bg-gray-300 h-8 rounded-md w-full mb-3'></div>
						<div className='title-line-2 bg-gray-300 h-8 rounded-md w-9/12'></div>
					</div>
					<div className='shimmer-div'>
						{/* <div className='shimmer venue bg-gray-300 h-4 rounded-md mb-2 w-2/5'></div> */}
						{/* <div className='shimmer organizer bg-gray-300 h-2 rounded-md w-2/4 mb-2'></div> */}
					</div>
					<div className='share h-11'></div>
				</div>
				<div className='shimmer event__img bg-gray-300 rounded-md w-44'></div>{" "}
			</div>
			<hr className='hr'></hr>
		</div>
	);
}



export function AnnouncementSkeleton() {
	return (
		<div className='flex flex-col mb-[1.2rem]'>
			<div className='wrapper'>
				<div className='flex flex-col w-full'>
					<div className='my-3 shimmer-div'>
						<div className='bg-gray-300 h-3 rounded-md w-16 mb-3'></div>
						<div className='title-line-1 bg-gray-300 h-9 rounded-md w-full mb-3'></div>
						<div className='title-line-2 bg-gray-300 h-9 rounded-md w-9/12'></div>
					</div>
				</div>
			</div>
			<hr className='hr'></hr>
		</div>
	);
}
