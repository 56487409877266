import { createContext, useContext, useState, useEffect } from "react";
// import {useLocation} from "react-router-dom";

import {
  formatTime,
  formatDate,
  formatDateFull,
} from "../utils/formatDateandTime.js";

const EventContext = createContext();

export const EventContextProvider = ({ children }) => {
  const [resources, setResources] = useState();
  const [imgFullScreen, setImgFullScreen] = useState(false);
  const [entriesLoading, setEntriesLoading] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activatedFilters, setActivatedFilters] = useState();

  const sendFilters = (val) => {
    setActivatedFilters(val);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const showImgFullScreen = (val) => {
    setImgFullScreen(val);
  };

  const fetchSingleEvent = async (eventId) => {
    let foundEvent = await fetch(
      `https://wknd-yarn-bck.vercel.app/entries/events/${eventId}`,
    ).then((res) => res.json());

    console.log("call for single resource made");

    return foundEvent;
  };

  const fetchEntries = async (query) => {
    let url = `https://wknd-yarn-bck.vercel.app/entries/events/?${query}`;

    try {
      let foundEntries = await fetch(url).then((res) => res.json());

      console.log("call for resources made to server");

      setResources(foundEntries);
    } catch (error) {
      console.log(error);
    } finally {
      setEntriesLoading(false);
    }
  };

  return (
    <EventContext.Provider
      value={{
        isMenuOpen,
        resources,
        imgFullScreen,
        entriesLoading,
        activatedFilters,
        sendFilters,
        toggleMenu,
        showImgFullScreen,
        fetchEntries,
        fetchSingleEvent,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export const EventResources = () => {
  return useContext(EventContext);
};
