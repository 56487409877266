import { useParams, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";

import { UserAuth } from "../context/AuthContext";

// Icons
import { ReactComponent as DateIcon } from "../assets/svgs/calendar-check.svg";
import { ReactComponent as TimeIcon } from "../assets/svgs/clock.svg";
import { ReactComponent as LocationIcon } from "../assets/svgs/location-dot.svg";
import { ReactComponent as ShareIcon } from "../assets/svgs/share-nodes.svg";
import { ReactComponent as OnlineEventIcon } from "../assets/svgs/video.svg";
import { ReactComponent as OpenLinkInNewTabIcon } from "../assets/svgs/arrow-up-right-from-square.svg";
import { ReactComponent as CloseIcon } from "../assets/svgs/xmark.svg";
import { ReactComponent as CalendarIcon } from "../assets/svgs/calendar.svg";
import { ReactComponent as ReminderIcon } from "../assets/svgs/bell-filled.svg";

// Contexts
import { EventResources } from "../context/EventContext.js";

// Components
import Category from "./Category.jsx";

// Functions
import { formatDateFull, formatTime } from "../utils/formatDateandTime.js";

export default function EventDetails() {
  const { imgFullScreen, showImgFullScreen, resources, fetchSingleEvent } =
    EventResources();

  const { token } = UserAuth();
  const adminMode = token ? true : false;

  const navigate = useNavigate();
  const { eventId } = useParams();

  const [event, setEvent] = useState(null);
  const [isAnnouncement, setIsAnnouncement] = useState(null);

  let dateDisplay = null;
  let timeDisplay = null;
  let venueDisplay = null;

  const modifyDateAndTime = (item) => {
    if (item.date) {
      item.date = formatDateFull(item.date);
    }
    if (item.startTime) {
      item.startTime = formatTime(item.startTime);
    }
    if (item.stopTime) {
      item.stopTime = formatTime(item.stopTime);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (resources) {
      resources.forEach((resource) => {
        if (resource.id === eventId) {
          let resourceCopy = { ...resource };
          modifyDateAndTime(resourceCopy);
          setEvent(resourceCopy);
          setIsAnnouncement(resource.category[0] === "announcement");
        }
      });
    } else {
      const fetcher = async () => {
        let event = await fetchSingleEvent(eventId);
        modifyDateAndTime(event);
        setEvent(event);
      };
      fetcher();
    }
  }, []);

  const handleDelete = async () => {
    try {
      const response = await fetch(
        `https://wknd-yarn-bck.vercel.app/entries/events/${event.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: "DELETE",
        },
      );
      if (response.status === 200) {
        navigate("/");
      }
      console.log(await response.json());
    } catch (error) {
      console.error("Error deleting event: " + error);
    }
  };

  const organizerLabel = {
    announcement: "Affected college",
    other: "Organizer",
  };

  let OrganizerDisplay = () => {
    if (event && event.category) {
      if (event.category === "announcement") {
        return (
          <p className="event__organizer | ff-title fw-600 text-xs">
            <span className="event__organizer-label ff-accent fw-500">
              {organizerLabel.announcement}
              {event.organizers.length > 1 ||
              event.organizers[0] === "all colleges"
                ? "s"
                : null}
              :{" "}
            </span>
            <span className="uppercase text-primary-900">
              {event.organizers.join(", ")}
            </span>
          </p>
        );
      } else {
        return (
          <p className="event__organizer text-primary-400| ff-title fs-400 fw-600 text-xs">
            <span className="event__organizer-label ff-accent fw-500">
              {organizerLabel.other}
              {event.organizers.length > 1 ? "s" : null}:{" "}
            </span>
            {event.organizers.join(", ")}
          </p>
        );
      }
    }
  };

  if (event && event.date) {
    dateDisplay = (
      <div className="flex items-center gap-4">
        <svg
          className={`} h-6 w-6 
					fill-accent`}
        >
          <DateIcon />
        </svg>
        <p className="font-lato text-sm">{event.date}</p>
      </div>
    );
  }

  if (event && event.startTime) {
    timeDisplay = (
      <div className="flex items-center gap-4">
        <svg
          className={`fill h-6 w-6 
					 fill-accent`}
        >
          <TimeIcon />
        </svg>
        <p className="font-lato text-sm">
          <span>{event.startTime}</span>
          {event.stopTime ? <span> to {event.stopTime}</span> : null}
        </p>
      </div>
    );
  }

  if (event && event.venue) {
    venueDisplay = (
      <div className="flex items-center gap-4">
        <svg className={`h-6 w-6 fill-accent `}>
          <LocationIcon />
        </svg>
        <span className="font-lato text-sm">{event.venue}</span>
      </div>
    );
  }

  if (event && event.isOnline && event.contactInfo.webLink) {
    venueDisplay = (
      <div className="flex items-center gap-4">
        <svg className="h-6 w-6 -rotate-2 fill-accent">
          <OnlineEventIcon />
        </svg>
        <div className="flex items-center gap-2">
          <a
            href={`${event.contactInfo.webLink}`}
            target="_blank"
            rel="noreferrer"
          >
            <span className="border-b border-accent font-lato text-sm">
              Online event
            </span>
          </a>
          <svg className="h-[1.2em] w-[1.2em] fill-accent">
            <OpenLinkInNewTabIcon />
          </svg>
        </div>
      </div>
    );
  }

  const imgRef = useRef(null);

  return (
    <div className={`${imgFullScreen ? "bg-black" : ""} min-h-full`}>
      {event && event.posterURL && (
        <div
          className={`flex h-screen items-center justify-center ${
            imgFullScreen ? "" : "hidden"
          }`}
          onClick={(e) => {
            if (imgFullScreen && !imgRef.current.contains(e.target)) {
              showImgFullScreen(false);
            }
          }}
        >
          <svg className="absolute right-6 top-6 z-50 h-5 w-5 fill-gray-50 ">
            <CloseIcon />
          </svg>
          <img
            ref={imgRef}
            className="max-h-full max-w-full"
            src={event.posterURL}
            alt=""
          ></img>
        </div>
      )}
      {event && (
        <div className={`text-primary-400 ${imgFullScreen ? "hidden" : ""}`}>
          {event.posterURL && (
            <div
              className="mb-4 h-[22vh] w-full overflow-hidden rounded-b-xl shadow-lg"
              onClick={() => showImgFullScreen(true)}
            >
              <img
                className="mb-8 h-full w-full object-cover"
                src={event.posterURL}
                alt=""
              ></img>
            </div>
          )}

          <div className="px-[1.3rem]">
            <div
              className={`${event.posterURL ? "" : "border-y border-gray-300"}`}
            >
              <div className="container my-8 space-y-2">
                {event.category && (
                  <Category
                    iconClassNames={["h-[12px]", "w-[12px]"]}
                    textClassNames={["fs-200"]}
                    category={event.category}
                  />
                )}
                <h1 className="ff-body fs-800 fw-800 font-black leading-[2.4rem] text-slate-900">
                  {event.title}
                </h1>
                <OrganizerDisplay />
              </div>
            </div>

            <div className="container-sm">
              <div className="mb-2 rounded-xl bg-gray-100 px-5 py-6">
                <div className="fs-400 fw-400 space-y-6">
                  {dateDisplay}
                  {timeDisplay}
                  {venueDisplay}
                </div>
              </div>

              <div className="mb-8 ">
                <button className="flex h-full items-center rounded-full bg-gray-100 px-5 py-2 font-lato text-sm">
                  <a
                    href={`${event.addToCalendarLink}`}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center gap-4"
                  >
                    <ReminderIcon className="h-5 w-5 rounded-[5px] fill-fuchsia-900" />{" "}
                    <span>Set reminder</span>
                  </a>
                </button>
              </div>

              {event.description && (
                <div className="space-y-4">
                  <h2 className="ff-title fw-700 mb-4 text-[19px] text-slate-900">
                    {isAnnouncement ? "Details" : "About this event"}
                  </h2>
                  <p
                    className="whitespace-normal break-words font-lato  text-[1rem]"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {event.description}
                  </p>
                </div>
              )}

              <div className="my-14 flex flex-row items-stretch justify-end gap-2 font-body font-normal">
                <button className="flex items-center justify-center rounded-md border border-gray-200 bg-gray-100 p-3 text-accent">
                  <svg className="h-5 w-5 fill-accent">
                    <ShareIcon />
                  </svg>
                </button>
                <button className="flex items-center justify-center rounded-md border border-gray-200 bg-gray-100 p-3">
                  <a
                    href={`${event.addToCalendarLink}`}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center gap-4"
                  >
                    <ReminderIcon className="h-5 w-5 rounded-[5px] fill-fuchsia-900" />{" "}
                  </a>
                </button>
                <button
                  className=" flex-grow rounded-md bg-accent p-3 text-sm font-semibold text-neutral-100"
                  onClick={() => {
                    if (resources) {
                      navigate(-1);
                    } else {
                      window.scrollTo(0, 0);
                      navigate("/");
                    }
                  }}
                >
                  See all events
                </button>
              </div>
              {adminMode && (
                <button
                  className="mb-6 w-full rounded-md border border-red-500 p-3 text-red-500"
                  onClick={handleDelete}
                >
                  {" "}
                  Delete event{" "}
                </button>
              )}
            </div>
            <div></div>
          </div>
        </div>
      )}
    </div>
  );
}
