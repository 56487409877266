import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import classNames from "classnames";

//Style sheets
import createPost from "../styles/createPost.module.css";

import { ReactComponent as CareerIcon } from "../assets/svgs/graduation-cap.svg";
import { ReactComponent as SocialIcon } from "../assets/svgs/masks-theater.svg";
import { ReactComponent as SportsIcon } from "../assets/svgs/basketball.svg";
import { ReactComponent as ReligiousIcon } from "../assets/svgs/person-praying.svg";
import { ReactComponent as AnnouncementIcon } from "../assets/svgs/bullhorn.svg";
import { ReactComponent as GamesIcon } from "../assets/svgs/dice.svg";
import { ReactComponent as LocationIcon } from "../assets/svgs/location-dot.svg";
import { ReactComponent as OnlineEventIcon } from "../assets/svgs/video.svg";

import { EventResources } from "../context/EventContext.js";

function Filters() {
  const { fetchEntries, toggleMenu, sendFilters } = EventResources();

  // STATES
  const [isAnnouncement, setIsAnnouncementState] = useState(false);
  const [selectedOrganizers, setSelectedOrganizers] = useState([
    "All Organizers",
  ]);
  const [selectedColleges, setSelectedColleges] = useState(["ALL COLLEGES"]);
  const [selectedCategories, setSelectedCategories] = useState([
    "all categories",
  ]);
  const [selectedType, setSelectedType] = useState("All Types");

  // ARRAYS
  const organizerArray = [
    "All Organizers",
    "NUESA",
    "SAMSSA",
    "COMHSSA",
    "LSS",
    "PANS",
    "COSSA",
    "SRC",
    "OTHERS",
  ];

  const collegeArray = [
    "ALL COLLEGES",
    "ENG",
    "LAW",
    "SMS",
    "MHS",
    "SCI",
    "PHARM",
  ];

  const categoryArray = [
    { name: "all categories" },
    {
      name: "social",
      icon: <SocialIcon />,
    },
    {
      name: "sports",
      icon: <SportsIcon />,
    },
    {
      name: "games",
      icon: <GamesIcon />,
    },
    {
      name: "career",
      icon: <CareerIcon />,
    },
    {
      name: "religious",
      icon: <ReligiousIcon />,
    },
    {
      name: "announcement",
      icon: <AnnouncementIcon />,
    },
  ];

  const typeArray = [
    { name: "All Types" },
    { name: "physical", icon: <LocationIcon /> },
    { name: "online", icon: <OnlineEventIcon /> },
  ];

  // USE EFFECTS
  useEffect(() => {
    if (selectedCategories.length === 0) {
      setSelectedCategories(["all categories"]);
    }
    if (selectedOrganizers.length === 0) {
      setSelectedOrganizers(["All Organizers"]);
    }
    if (selectedColleges.length === 0) {
      setSelectedColleges(["ALL COLLEGES"]);
    }
  }, [selectedCategories, selectedOrganizers, selectedColleges]);

  // FUNCTIONS

  const handleCategoryClick = (cat) => {
    setIsAnnouncementState(cat === "announcement");

    // If the option is already selected, remove it. Do nothing if the option is "all categories"
    if (selectedCategories.includes(cat)) {
      if (cat !== "all categories") {
        setSelectedCategories(selectedCategories.filter((opt) => opt !== cat));
      }
    } else {
      // If the option is "all categories" or "announcment", set the selectedCategories array to just the option.
      // If the option is not selected already, and the option is anything besides "all categories" or "announcement", first remove "all categories" and "announcement" from the array, then add the option
      setSelectedCategories((prev) => {
        let updatedCategories;
        if (cat === "announcement" || cat === "all categories") {
          updatedCategories = [cat];
          if (cat === "announcement") {
            setSelectedOrganizers([]);
          } else setSelectedColleges([]);
        } else {
          if (cat !== "announcement") setSelectedColleges([]);
          updatedCategories = [
            ...prev.filter(
              (selected) =>
                selected !== "all categories" && selected !== "announcement",
            ),
            cat,
          ];
        }

        return updatedCategories;
      });
    }
  };

  const handleFilterClick = ({ type, val }) => {
    let operator, operated, exclusive;
    switch (type) {
      case "college":
        operator = setSelectedColleges;
        operated = selectedColleges;
        exclusive = "ALL COLLEGES";
        break;
      case "organizer":
        operator = setSelectedOrganizers;
        operated = selectedOrganizers;
        exclusive = "All Organizers";
        break;
      default:
        operator = null;
        operated = null;
        exclusive = null;
    }

    if (operated.includes(val)) {
      // If the option is already selected, remove it
      operator(operated.filter((selected) => selected !== val));
    } else {
      // If the option is not selected, add it
      // If the option is not selected, and the option is anything other than "exclusive", first remove "exclusive" from the array, then add the option
      operator((prev) => {
        let updatedColleges;
        if (val !== exclusive) {
          updatedColleges = [
            ...prev.filter((selected) => selected !== exclusive),
            val,
          ];
        } else if (val === exclusive) {
          updatedColleges = [exclusive];
        } else {
          updatedColleges = [...prev, val];
        }
        return updatedColleges;
      });
    }
  };

  // COMPONENTS

  const buttonClasses = classNames(
    "flex items-center justify-center gap-[2.5px] px-4 py-2 rounded-full fill-accent-bright border border-accent-bright text-accent-bright cursor-pointer",
  );

  const selectedButtonClasses = classNames(
    "fill-white bg-accent-bright text-gray-50",
  );

  const CategorySelector = () => {
    return (
      <div>
        <ul className="flex flex-wrap gap-2">
          {categoryArray.map((category) => {
            return (
              <li
                key={category.name}
                className={`${buttonClasses} ${
                  selectedCategories.includes(category.name) &&
                  `${selectedButtonClasses}`
                }`}
                onClick={() => handleCategoryClick(category.name)}
              >
                {category.name !== "all categories" && (
                  <svg
                    width="15"
                    height="15"
                    className={`${
                      category.name === "announcement" && "-rotate-6"
                    }`}
                  >
                    {category.icon}
                  </svg>
                )}
                <p className="fs-vsmall ff-body fw-600 text-xs capitalize">
                  {category.name}
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const CollegeSelector = () => {
    return (
      <div>
        <ul className="flex flex-wrap gap-2">
          {collegeArray &&
            collegeArray.map((college) => {
              return (
                <li
                  key={college}
                  className={`${buttonClasses} ${
                    selectedColleges.includes(college) && selectedButtonClasses
                  }`}
                  onClick={() =>
                    handleFilterClick({ type: "college", val: college })
                  }
                >
                  <p className="ff-body fw-600 text-xs">{college}</p>
                </li>
              );
            })}
        </ul>
      </div>
    );
  };

  const OrganizerSelector = () => {
    return (
      <div>
        <ul className="flex flex-wrap gap-2">
          {organizerArray &&
            organizerArray.map((organizer) => {
              return (
                <li
                  key={organizer}
                  className={`${buttonClasses} ${
                    selectedOrganizers.includes(organizer) &&
                    selectedButtonClasses
                  }`}
                  onClick={() =>
                    handleFilterClick({ type: "organizer", val: organizer })
                  }
                >
                  <p className="ff-body fw-600 text-xs">{organizer}</p>
                </li>
              );
            })}
        </ul>
      </div>
    );
  };

  const TypeSelector = () => {
    return (
      <div>
        <ul className="flex flex-wrap gap-2">
          {typeArray.map((type) => {
            return (
              <li
                key={type.name}
                className={`${buttonClasses}
								} ${selectedType === type.name && selectedButtonClasses}`}
                onClick={() => setSelectedType(type.name)}
              >
                {type.name !== "All Types" && (
                  <svg
                    width="15"
                    height="15"
                    className={`${type.name === "online" ? "-rotate-2" : ""}`}
                  >
                    {type.icon}
                  </svg>
                )}
                <p className="ff-body fw-600 text-xs capitalize">{type.name}</p>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({});

  // Update local state when URL parameters change
  useEffect(() => {
    const params = new URLSearchParams(searchParams);
    const updatedFilters = {};
    for (const [key, value] of params.entries()) {
      updatedFilters[key] = value;
    }
    setFilters(updatedFilters);
  }, [searchParams]);

  const valuesToExclude = ["All Organizers", "all categories", "ALL COLLEGES"];

  // console.log(
  // 	[...selectedColleges].filter((el) => !valuesToExclude.includes(el))
  // );

  const filterVals = [
    {
      name: "category",
      value: [...selectedCategories].filter(
        (el) => !valuesToExclude.includes(el),
      ),
    },
    {
      name: "organizers",
      value: [...selectedColleges, ...selectedOrganizers].filter(
        (el) => !valuesToExclude.includes(el),
      ),
    },
    { name: "isOnline", value: selectedType === "online" ? ["true"] : "" },
  ];

  const createFilter = () => {
    handleQuerySubmit();
    toggleMenu();
  };

  let filtersToShare = [];

  // Function to handle sending the query to the server
  const handleQuerySubmit = () => {
    const queryString = filterVals
      .map((filter, index) =>
        filter.value.length ? `${filter.name}=${filter.value}` : "",
      )
      .filter(Boolean)
      .join("&");
    setSearchParams(queryString);
    fetchEntries(queryString);
    filterVals.forEach((filter) => {
      if (filter.value) {
        filter.value.forEach((filterToShare) =>
          filtersToShare.push(filterToShare),
        );
      }
    });
    if (selectedType === "online") {
      filtersToShare.push("Online");
    } else if (selectedType === "physical") {
      filtersToShare.push("Physical");
    }
    sendFilters(filtersToShare);
  };

  //   let isVisible = status.visible;

  return (
    <div className="-mt-[60px] ">
      <div className="overflow-scroll">
        {/* <div className="mb-4 flex justify-center text-lg font-bold">
          <p className="mx-auto font-urbanist">Filter Events</p>
        </div> */}
        <div className="mb-8 space-y-3">
          <p className="font-urbanist font-bold">Choose categories</p>
          <CategorySelector />
        </div>
        <div className="mb-8 space-y-3">
          <p className="font-urbanist font-bold">
            {isAnnouncement ? "Choose colleges" : "Choose organizers"}
          </p>
          {isAnnouncement ? <CollegeSelector /> : <OrganizerSelector />}
        </div>
        <div className="mb-10 space-y-3">
          <p className="font-urbanist font-bold">Choose a type</p>
          <TypeSelector />
        </div>
        <div className="flex flex-row justify-center">
          <button
            className="rounded-xl border-2 bg-accent-bright px-14 py-2 font-urbanist text-sm font-bold uppercase text-white"
            onClick={() => {
              window.scrollTo(0, 0);
              createFilter();
            }}
          >
            Filter
          </button>
        </div>
      </div>
    </div>
  );
}

export default Filters;
