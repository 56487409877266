import "../styles/index.css";

import { ReactComponent as CareerIcon } from "../assets/svgs/graduation-cap.svg";
import { ReactComponent as SocialIcon } from "../assets/svgs/masks-theater.svg";
import { ReactComponent as SportsIcon } from "../assets/svgs/basketball.svg";
import { ReactComponent as ReligiousIcon } from "../assets/svgs/person-praying.svg";
import { ReactComponent as AnnouncementIcon } from "../assets/svgs/bullhorn.svg";
import { ReactComponent as GamesIcon } from "../assets/svgs/dice.svg";

const Category = (props) => {
	const cat = props.category;
	const textClassNames = props.textClassNames
		? props.textClassNames.join(" ")
		: "";
	const iconClassNames = props.iconClassNames
		? props.iconClassNames.join(" ")
		: "";
	let categoryIcon;

	switch (cat) {
		case "sports":
			categoryIcon = <SportsIcon />;
			break;
		case "games":
			categoryIcon = <GamesIcon />;
			break;
		case "social":
			categoryIcon = <SocialIcon />;
			break;
		case "career":
			categoryIcon = <CareerIcon />;
			break;
		case "religious":
			categoryIcon = <ReligiousIcon />;
			break;
		default:
			categoryIcon = <AnnouncementIcon />;
	}

	return (
		<div className='category-flex-container'>
			<svg className={`${cat === "announcement" ? '-rotate-6' :''} ${iconClassNames}`}>
				{categoryIcon}
			</svg>
			<p
				className={`event__category ff-body fw-600 ${textClassNames}`}
			>
				{props.category || <span>Announcement</span>}
			</p>
		</div>
	);
};

export default Category;
