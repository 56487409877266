import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import "../styles/index.css";
import createPost from "../styles/createPost.module.css";

import { ReactComponent as CareerIcon } from "../assets/svgs/graduation-cap.svg";
import { ReactComponent as SocialIcon } from "../assets/svgs/masks-theater.svg";
import { ReactComponent as SportsIcon } from "../assets/svgs/basketball.svg";
import { ReactComponent as ReligiousIcon } from "../assets/svgs/person-praying.svg";
import { ReactComponent as AnnouncementIcon } from "../assets/svgs/bullhorn.svg";
import { ReactComponent as GamesIcon } from "../assets/svgs/dice.svg";

import { UserAuth } from "../context/AuthContext";

export default function CreatePost(props) {
  let isToBeUpdated = Object.keys(props).length > 0;

  const navigate = useNavigate();

  const { token, logOut } = UserAuth();

  const organizerArray = [
    "NUESA",
    "SAMSSA",
    "COMHSSA",
    "LSS",
    "PANS",
    "COSSA",
    "SRC",
    "OTHERS",
  ];

  const collegeArray = [
    "ALL COLLEGES",
    "ENG",
    "LAW",
    "SMS",
    "MHS",
    "SCI",
    "PHARM",
  ];

  let listedOrganizersSent = [];
  let otherOrganizersSent = [];

  if (props.organizers && props.category !== "announcement") {
    listedOrganizersSent = props.organizers;
    listedOrganizersSent.forEach((organizer) => {
      if (!organizerArray.includes(organizer)) {
        listedOrganizersSent.filter((item) => item !== organizer);
        otherOrganizersSent.push(organizer);
      }
    });
  } else {
    listedOrganizersSent = props.organizers;
  }

  const posterInputRef = useRef(null);

  const [image, setImage] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(
    isToBeUpdated ? props.category : "announcement",
  );
  const [isAnnouncement, setIsAnnouncementState] = useState(true);
  const [selectedOrganizers, setSelectedOrganizers] = useState(
    isToBeUpdated ? [listedOrganizersSent] : [],
  );
  const [unlistedOrganizersPresent, setUnlistedOrganizersPresent] =
    useState(null);
  const [unlistedOrganizers, setUnlistedOrganizers] = useState("");
  const [imageToSend, setImageToSend] = useState(null);
  const [isOnline, setIsOnline] = useState(false);

  const [formInfo, setFormInfo] = useState({
    title: "",
    venue: "",
    date: "",
    timeFrom: "",
    timeTo: "",
    description: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormInfo({
      ...formInfo,
      [name]: value,
    });
  };

  const categories = [
    {
      name: "social",
      icon: <SocialIcon />,
    },
    {
      name: "sports",
      icon: <SportsIcon />,
    },
    {
      name: "games",
      icon: <GamesIcon />,
    },
    {
      name: "career",
      icon: <CareerIcon />,
    },
    {
      name: "religious",
      icon: <ReligiousIcon />,
    },
    {
      name: "announcement",
      icon: <AnnouncementIcon />,
    },
  ];

  const handlePosterButtonClick = () => {
    posterInputRef.current.click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageToSend(file);
    // Display image preview
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImage(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    Array.from(e.target.elements).forEach((element) => {
      if (element.name === "organizers") {
        let organizersToSend = element.value.split(",");
        if (unlistedOrganizersPresent) {
          let organizersToAdd = unlistedOrganizers.split(",");
          organizersToSend = organizersToSend.filter(
            (item) => item !== "OTHERS",
          );
          organizersToSend = organizersToSend.concat(organizersToAdd);
          element.value = organizersToSend;
        }
      }
      if (element.name) {
        formData.append(element.name, element.value);
      }
    });

    formData.append("image", imageToSend);

    try {
      // const url = "http://localhost:9000/entries/events/";
      const url = "https://wknd-yarn-bck.vercel.app/entries/events";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => console.log(data));

      navigate("/createpost");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleCategoryClick = (option) => {
    //If selectedCategory is changed from announcement, reset the selectedOrganizers

    setIsAnnouncementState(() => {
      let update;
      if (option === "announcement" || isAnnouncement) {
        update = true;
        setSelectedOrganizers([]);
      } else {
        update = false;
      }
      return update;
    });

    setIsAnnouncementState(option === "announcement");

    setSelectedCategory(option);
  };

  const CategorySelector = () => {
    return (
      <div>
        <ul className={createPost.selectorField}>
          {categories.map((category) => {
            return (
              <li
                key={category.name}
                className={`flex items-center gap-[2.5px] ${
                  createPost.option
                } ${
                  selectedCategory === category.name
                    ? createPost.selected
                    : null
                }`}
                onClick={() => handleCategoryClick(category.name)}
              >
                <svg
                  width="15"
                  height="15"
                  className={`${
                    category.name === "announcement" ? "-rotate-6" : ""
                  }`}
                >
                  {category.icon}
                </svg>
                <p className="event__category fs-vsmall ff-body fw-600 text-sm">
                  {category.name}
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const handleCollegeClick = (college) => {
    if (selectedOrganizers.includes(college)) {
      // If the option is already selected, remove it
      setSelectedOrganizers(
        selectedOrganizers.filter((selected) => selected !== college),
      );
    } else {
      // If the option is not selected, add it
      // If the option is not selected, and the option is anything other than "all colleges", first remove "all colleges" from the array, then add the option
      setSelectedOrganizers((prev) => {
        let updatedOrganizers;
        if (college !== "ALL COLLEGES") {
          updatedOrganizers = [
            ...prev.filter((selected) => selected !== "ALL COLLEGES"),
            college,
          ];
        } else if (college === "ALL COLLEGES") {
          updatedOrganizers = ["ALL COLLEGES"];
        } else {
          updatedOrganizers = [...prev, college];
        }
        return updatedOrganizers;
      });
    }
  };

  useEffect(() => {
    setUnlistedOrganizersPresent(selectedOrganizers.includes("OTHERS"));
  }, [selectedOrganizers]);

  const CollegeSelector = () => {
    return (
      <div>
        <ul className={createPost.selectorField}>
          {collegeArray &&
            collegeArray.map((college) => {
              return (
                <li
                  key={college}
                  className={`${createPost.option} ${
                    selectedOrganizers.includes(college)
                      ? createPost.selected
                      : null
                  }`}
                  onClick={() => handleCollegeClick(college)}
                >
                  <p className="ff-body fw-600 text-sm">{college}</p>
                </li>
              );
            })}
        </ul>
      </div>
    );
  };

  const handleOrganizerClick = (organizer) => {
    if (selectedOrganizers.includes(organizer)) {
      // If the option is already selected, remove it
      setSelectedOrganizers(
        selectedOrganizers.filter((selected) => selected !== organizer),
      );
    } else {
      // If the option is not selected, add it
      setSelectedOrganizers([...selectedOrganizers, organizer]);
    }
  };

  const OrganizerSelector = () => {
    return (
      <div className="space-y-2">
        <ul className={createPost.selectorField}>
          {organizerArray &&
            organizerArray.map((organizer) => {
              return (
                <li
                  key={organizer}
                  className={`${createPost.option} ${
                    selectedOrganizers.includes(organizer)
                      ? createPost.selected
                      : null
                  }`}
                  onClick={() => handleOrganizerClick(organizer)}
                >
                  <p className="ff-body fw-600 text-sm">{organizer}</p>
                </li>
              );
            })}
        </ul>
      </div>
    );
  };

  const handleLogOut = async () => {
    try {
      logOut();
      navigate("/signin");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="my-7 flex justify-center">
      <div className="container max-w-lg px-5">
        <h1 className={`mb-6 text-xl md:mt-4 ${createPost.heading}`}>
          {isToBeUpdated ? "Update event" : "Post an event"}
        </h1>
        <hr className="mb-8 h-px bg-gray-400" />
        <form
          className="space-y-5"
          encType="multipart/form-data"
          onSubmit={handleSubmit}
        >
          {image && (
            <div className="flex justify-center">
              <div className="overflow-hidden rounded-md border border-gray-300 shadow-lg">
                <img
                  src={image}
                  alt="Preview of poster"
                  className="h-48 object-contain"
                />
              </div>
            </div>
          )}
          <div className={createPost.centralizedBtnDiv}>
            <label
              className={`${createPost.btnSecondary} ${createPost.btnLg} pointer-event-auto rounded-md px-4 py-2`}
              onClick={handlePosterButtonClick}
            >
              {image ? "Change poster" : "Upload a poster"}
            </label>
            <input
              type="file"
              className="visually-hidden"
              accept="image/*"
              onChange={handleImageChange}
              ref={posterInputRef}
            />
          </div>

          <div className="space-y-1">
            <label htmlFor="event-category">
              Category{" "}
              <span className="text-sm italic leading-5 text-gray-700">
                ("Announcement" is selected by default)
              </span>
            </label>
            <CategorySelector />
            <input
              type="radio"
              id="event-category"
              name="category"
              value={selectedCategory || "announcement"}
              className="visually-hidden"
              readOnly
            />
          </div>

          <div>
            <label htmlFor="event-title">
              {isAnnouncement ? (
                <span>Announcement </span>
              ) : (
                <span>Title </span>
              )}{" "}
            </label>
            <br />
            <textarea
              required
              rows="2"
              id="event-title"
              name="title"
              value={isToBeUpdated ? props.title : formInfo.title}
              onChange={handleInputChange}
              className="block w-full rounded-md border-0 shadow-sm ring-1 ring-inset ring-inactive focus:ring-2 focus:ring-inset focus:ring-primary-400 sm:text-sm sm:leading-6"
            />
          </div>

          <div className={`${isAnnouncement ? "visually-hidden" : null}`}>
            <div className="flex items-center">
              <input
                id="event-isOnline"
                name="isOnline"
                type="checkbox"
                checked={isOnline}
                onChange={() => setIsOnline(!isOnline)}
                value={isOnline}
                className="h-4 w-4 rounded border-gray-300 bg-gray-100 focus:ring-2 focus:ring-primary-400 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
              />
              <label
                for="event-isOnline"
                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                This is an online event
              </label>
            </div>
            <label htmlFor="venue">
              {isOnline ? <span>Link to event</span> : <span>Venue</span>}{" "}
            </label>
            <br />

            {isOnline ? (
              <input
                type="text"
                id="event-link"
                name="webLink"
                placeholder="Please enter a valid URL"
                onChange={handleInputChange}
                className="w-full rounded-md border-0 shadow-sm ring-1 ring-inset ring-inactive focus:ring-2 focus:ring-inset focus:ring-primary-400"
              />
            ) : (
              <input
                type="text"
                id="event-venue"
                name="venue"
                value={isToBeUpdated ? props.venue : formInfo.venue}
                onChange={handleInputChange}
                className="w-full rounded-md border-0 shadow-sm ring-1 ring-inset ring-inactive focus:ring-2 focus:ring-inset focus:ring-primary-400"
              />
            )}
          </div>

          <div>
            <label htmlFor="event-date">Date </label>
            <input
              type="date"
              id="event-date"
              name="date"
              className="w-full rounded-md border-0 shadow-sm ring-1 ring-inset ring-inactive focus:ring-2 focus:ring-inset focus:ring-primary-400"
            />
          </div>

          <div>
            <p className="block font-bold">Time</p>
            <div className="flex items-center space-x-2">
              <label htmlFor="event-start-time">From </label>
              <input
                type="time"
                id="event-start-time"
                name="startTime"
                className="flex-1 rounded-md border-0 shadow-sm ring-1 ring-inset ring-inactive focus:ring-2 focus:ring-inset focus:ring-primary-400"
              />
              <label htmlFor="event-stop-time"> to </label>
              <input
                type="time"
                id="event-stop-time"
                name="stopTime"
                className="flex-1 rounded-md border-0 shadow-sm ring-1 ring-inset ring-inactive focus:ring-2 focus:ring-inset focus:ring-primary-400"
              />
            </div>
          </div>

          <div className="space-y-1">
            <label htmlFor="organizers">
              {isAnnouncement ? (
                <span>Affected colleges </span>
              ) : (
                <span>Organizers </span>
              )}{" "}
            </label>{" "}
            {isAnnouncement ? <CollegeSelector /> : <OrganizerSelector />}
            {unlistedOrganizersPresent ? (
              <div className="space-y-1">
                <label>
                  <p className="text-sm leading-5 text-gray-700">
                    Enter the names of the other organizers, separate with a
                    comma{" "}
                    <span className="block italic">
                      (e.g. Abuad Green Club, Dr. Eddie Babalola)
                    </span>
                  </p>
                </label>{" "}
                <input
                  type="text"
                  value={
                    isToBeUpdated
                      ? otherOrganizersSent.join(", ")
                      : unlistedOrganizers
                  }
                  className="w-full rounded-md border-0 shadow-sm ring-1 ring-inset ring-inactive focus:ring-2 focus:ring-inset focus:ring-primary-400"
                  onChange={(e) => {
                    setUnlistedOrganizers(e.target.value);
                    // console.log(unlistedOrganizers);
                  }}
                />
              </div>
            ) : null}
            <input
              type="text"
              id="event-organizers"
              name="organizers"
              value={
                selectedOrganizers.join(",") ||
                (isAnnouncement ? "all colleges" : "")
              }
              className="visually-hidden"
              readOnly
            />
          </div>

          <div>
            <label htmlFor="event-description">
              {isAnnouncement ? <span>Details</span> : <span>Description</span>}
            </label>
            <br />
            <textarea
              rows="7"
              id="event-description"
              name="description"
              value={isToBeUpdated ? props.description : formInfo.description}
              onChange={handleInputChange}
              className="block w-full rounded-md border-0 shadow-sm ring-1 ring-inset ring-inactive focus:ring-2 focus:ring-inset focus:ring-primary-400 sm:text-sm sm:leading-6"
            />
          </div>

          <div className="space-y-1">
            <label className="block" htmlFor="event-phone">
              Phone numbers
            </label>
            <p className="text-xs text-gray-500">
              Separate multiple entries with a comma
            </p>
            <input
              type="text"
              id="event-phone"
              name="phoneNumber"
              className="w-full rounded-md border-0 shadow-sm ring-1 ring-inset ring-inactive focus:ring-2 focus:ring-inset focus:ring-primary-400"
            />
          </div>

          <div className="space-y-1">
            <label className="block" htmlFor="event-email">
              Email address
            </label>
            <p className="text-xs text-gray-500">
              Separate multiple entries with a comma
            </p>
            <input
              type="email"
              id="event-email"
              name="emailAddress"
              className="w-full rounded-md border-0 shadow-sm ring-1 ring-inset ring-inactive focus:ring-2 focus:ring-inset focus:ring-primary-400"
            />
          </div>

          {isOnline ? null : (
            <div className="space-y-1">
              <label className="block" htmlFor="event-link">
                Web link
              </label>
              <p className="text-xs text-gray-500">
                You can enter only one (1) web link
              </p>
              <input
                type="text"
                id="event-link"
                name="webLink"
                placeholder="Please enter a valid URL"
                className="w-full rounded-md border-0 shadow-sm ring-1 ring-inset ring-inactive placeholder:text-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-400"
              />
            </div>
          )}

          <div className="flex justify-between pt-4">
            <button
              className={`w-1/4 rounded-md border border-red-700 py-2 text-red-700`}
              type="button"
              onClick={handleLogOut}
            >
              Logout
            </button>
            <button
              className={`w-1/4 rounded-md bg-primary-300 py-2 text-neutral-100`}
              type="submit"
            >
              {isToBeUpdated ? "Update" : "Post"}
            </button>
          </div>

          <div className="h-8"></div>
        </form>
      </div>
    </div>
  );
}
